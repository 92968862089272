import { showModal } from 'modules/common/components/Modal';
import React, { useState } from 'react';
import placeholder from 'assets/img/logo-biller.jpg';
import history from 'setup/history';

export const showPreview = ({ data, callback }) => {
  showModal({
    title: 'Preview',
    content: (onClose) => (
      <ProfilePreviewModal data={data} callback={callback} onClose={onClose} />
    ),
  });
};

function Info({ label, width, children }) {
  return (
    <div className="flex space-x-2">
      <div className="text-right font-thin flex-shrink-0" style={{ width }}>
        {label}
      </div>
      <div className="font-semibold">{children}</div>
    </div>
  );
}

function ProfilePreviewModal({ data, callback, onClose }) {
  const [isAgree, setIsAgree] = useState(false);
  return (
    <div className="p-4 text-sm relative">
      <div className="absolute right-0 p-4 transform -translate-y-16">
        <img
          className="h-24 md:h-16 rounded object-contain object-right"
          src={data.photo || placeholder}
          alt=""
        />
      </div>
      <div className="uppercase mb-2">Applicant Information</div>
      <div className="flex space-x-2 mb-4 flex-col sm:flex-row">
        <div className="flex-1">
          <Info label="First Name" width="110px">
            {data.first_name}
          </Info>
          <Info label="Middle Name" width="110px">
            {data.middle_name}
          </Info>
          <Info label="Last Name" width="110px">
            {data.last_name}
          </Info>
          <Info label="Husband Surname" width="110px">
            {data.husband_surname}
          </Info>
          <Info label="Gender" width="110px">
            {data.gender}
          </Info>
          <Info label="Nickname" width="110px">
            {data.nickname}
          </Info>
          <Info label="AKA/Other Name" width="110px">
            {data.alias}
          </Info>
        </div>
        <div className="flex-shrink-0">
          <Info label="Civil Status" width="110px">
            {data.civil_status}
          </Info>
          <Info label="Birth Date" width="110px">
            {data.birth_date}
          </Info>
          <Info label="Birth Place" width="110px">
            {data.birth_place}
          </Info>
          <Info label="Citizenship" width="110px">
            {data.citizenship}
          </Info>
        </div>
      </div>
      <div className="uppercase mb-2">Contact Details</div>
      <div className="flex space-x-2 mb-4 flex-col sm:flex-row">
        <div className="flex-1">
          <Info label="Address" width="110px">
            {data.address}
          </Info>
          <Info label="Mobile" width="110px">
            {data.mobile}
          </Info>
          <Info label="E-mail" width="110px">
            {data.email}
          </Info>
        </div>
      </div>
      <div className="flex space-x-2 flex-col sm:flex-row">
        <div className="flex-1 mb-4">
          <div className="uppercase mb-2">Family Background</div>
          <Info label="Spouse Name" width="110px">
            {data.spouse_name}
          </Info>
          <Info label="Spouse Birth Place" width="110px">
            {data.spouse_birth_place}
          </Info>
          <Info label="Father Name" width="110px">
            {data.father_name}
          </Info>
          <Info label="Father Birth Place" width="110px">
            {data.father_birth_place}
          </Info>
          <Info label="Mother Name" width="110px">
            {data.mother_name}
          </Info>
          <Info label="Mother Birth Place" width="110px">
            {data.mother_birth_place}
          </Info>
        </div>
        <div className="flex-1 mb-4">
          <div className="uppercase mb-2">Educational Background</div>
          <Info label="Educ. Attainment" width="110px">
            {data.education_attainment}
          </Info>
          <Info label="Occupation" width="110px">
            {data.occupation}
          </Info>
          <Info label="Religion" width="110px">
            {data.religion}
          </Info>
          <Info label="Height" width="110px">
            {data.height}
          </Info>
          <Info label="Weight" width="110px">
            {data.weight}
          </Info>
          <Info label="Complexion" width="110px">
            {data.complexion}
          </Info>
          <Info label="Identifying Marks" width="110px">
            {data.identifying_marks}
          </Info>
        </div>
      </div>
      {/* <div className="text-sm">
        <div>Note:</div>
        <ul className="list-decimal pl-4">
          <li>Take Screenshot of this record for your future references.</li>
          <li>Renewal but previous NBI clearance has correction/error/changes in any field, even if a single letter is to be corrected, needs to register online again and don&apos;t proceed this quick renewal application.</li>
        </ul>
      </div> */}
      <div className="mt-4 text-center">
        <p>
          Confirmed NBI applications{' '}
          <strong className="font-bold">will NOT be refunded</strong>.
        </p>
        <p className="mb-3">Please make sure the details above are correct.</p>
        <label className="block flex justify-center items-center space-x-2">
          <input
            type="checkbox"
            checked={isAgree}
            onChange={(e) => setIsAgree(e.target.checked)}
          />
          <span>I confirm and certify that the details are correct.</span>
        </label>
      </div>
      <div className="text-center mt-2">
        <button
          className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
          disabled={!isAgree}
          style={{ opacity: isAgree ? 1 : 0.3 }}
          onClick={() => {
            callback();
            onClose();
          }}
        >
          Continue
        </button>
        <button
          className="text-white ml-3"
          type="button"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Are you sure you want to cancel?')) {
              onClose();
              history.push('/');
            }
          }}
        >
          Cancel
        </button>
      </div>
      <hr className="my-4" />
      <div>
        <p className="mb-2">
          Incorrect or outdated information?
          <br />
          Please <b>&quot;Sign In&quot;</b> to your account OR{' '}
          <b>&quot;Register&quot;</b> to create a new account and ensure your
          personal information is up-to-date.
          <br />
          Additionally, reapply for clearance and book an appointment schedule.
        </p>
        <p className="mb-2">
          If you need any assistance, please call the following help desk
          hotline numbers:
        </p>
        <ul className="list-disc pl-4">
          <li>09617319404</li>
          <li>09608915222</li>
          <li>09608915224</li>
          <li>09608287044</li>
          <li>09551405330</li>
        </ul>
      </div>
    </div>
  );
}

export default ProfilePreviewModal;
